import './App.css';
import NavMenu from './NavMenu/NavMenu.jsx';
import Project from './Project/Project.jsx'
import About from './About/About.jsx';
import Contact from './Contact/Contact.jsx'
import { papertrailImages, memupImages } from '../../images/images'

function App() {
  return (
    <div className="App">
      <header id="top">
        <NavMenu />
      </header>
      <main>
        <section className="about">
          <About />
        </section>
        <section id="projects">
            <h1 className="projects-title section-header">MY PROJECTS</h1>
          <Project 
            images={papertrailImages}
            projectTitle="PaperTrail"
            projectDescription="PaperTrail, designed for use with the Refold language learning system, is a web application to track your anime watch history and find new content to watch."
            projectObjectives="As my first big solo project, my main objective with PaperTrail was to further my understanding of Angular and gain experience using Firebase and Firestore as a backend. This presented the unique challenge of deciding when to handle data locally vs. server side, as each query to a firestore database counts towards your daily quota. I worked with the Kitsu API to retrieve data on a large number of anime series, which gave me the opportunity to work on handling API responses and creating models to do so. Additionally, I was able to make use of external libraries to handle authentication (AngularFireAuth) and data presentation (google-charts)."
            githubLink="https://github.com/AndrewB21/papertrail"
            projectLink="https://papertrail.abumpus.dev"
            techs={['Angular', 'Firestore']}
          />
          <Project 
          images={memupImages} 
            projectTitle="MemUp"
            projectDescription="A Spaced Repetition language learning application to help users study vocabulary for their target language."
            projectObjectives="As a collaborative project, the main goal of MemUp was to gain experience working with another developer on a large project. We chose to use .NET Core for our backend as allowed us to use Entity Framework and a code first approach for database operations. Additionally we were able to use the Identity Framework to handle user authentication and roles. Using .NET was extremely challenging as I had no experience with server side languages prior to this project, but by diving in head first I was able to learn alot about how databases handle operations, the dangers of circular dependencies, the importance of properly designed models, and much more very quickly. I also had the opportunity to work with SQL in order to seed our original language database from an Excel document. Although
            it is not ready for release yet, much of the backend work has been completed and development is ongoing. Users can perform CRUD operations from the frontend to store and retrieve data on a .NET Core backend."
            githubLink="https://github.com/noah231515/memup"
            techs={['Angular', '.NET Core', 'C#', 'Identity Framework', 'SQL']}
          />
        </section>
        <Contact />
      </main>
      <footer>
        <span id="return-link"><a href="#top">Return to top</a></span>
      </footer>
    </div>
  );
}

export default App;
