import './NavMenu.css'

function NavMenu() {
  
  return (
    <nav>
      <span className="nav-name">Andrew Bumpus</span>
      <div className="nav-links">
        <ul className="nav-list">
          <li>
            <a 
              target="_blank"
              rel="noreferrer"
              href="https://drive.google.com/file/d/1I4tGcWDv__N1dbjL8Qotb7T0B6ip4gMW/view?usp=sharing">
                Resume
              </a>
          </li>
          <li>
            <a href="#contact">Contact Me</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavMenu;
