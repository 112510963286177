import './Project.css'
import TechCard from '../About/TechCard/TechCard';
import CodeIcon from '@material-ui/icons/Code';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import { useEffect, useState } from 'react';

function Project(props) {
    const [projectLink, setProjectLink] = useState(props.projectLink);
    const [githubLink, setGithubLink] = useState(props.githubLink);
    const [projectLinkDisplay, setProjectLinkDisplay] = useState("auto");
    const [githubLinkDisplay, setGithubLinkDisplay] = useState("auto");
    const [linkTargets, setLinkTargets] = useState({
        projectLink: '_blank',
        githubLink: '_blank',
    })


    useEffect(() => {
        if(!props.projectLink) {
            setProjectLinkDisplay('none');
            setProjectLink(`#${props.projectTitle}`);
            setLinkTargets({
                projectLink: '_self',
                githubLink: linkTargets.githubLink,
            })
        }
        if(!props.githubLink) {
            setGithubLinkDisplay('none');
            setGithubLink(`#${props.projectTitle}`);
            setLinkTargets({
                projectLink: linkTargets.projectLink,
                githubLink: '_self',
            })
        }
    }, [props.projectLink, props.githubLink]);


    return (
        <article className="project" id={props.projectTitle}>
            <div className="project-img-container">
                <a target={linkTargets.projectLink} rel="noreferrer" href={projectLink} aria-label={`${props.projectTitle} live version link`}>
                    <img alt={`${props.projectTitle} Mobile Dashboard`} className="project-img" src={props.images.mobileDash}></img>
                </a>
            </div>
            <div className="project-info">
                <div className="project-title-container">
                    <h1 className="project-title">
                        <a className="project-title-link" target={linkTargets.projectLink} rel="noreferrer" href={projectLink} aria-label={`${props.projectTitle} live version link`}>
                            {props.projectTitle}
                        </a>
                    </h1>
                    <a target={linkTargets.githubLink} rel="noreferrer" className="project-link" href={githubLink} aria-label={`${props.projectTitle} github link`}>
                        <CodeIcon id="code-icon" fontSize="large" style={{ color: '#33aab9', display: githubLinkDisplay}}/>
                        <span className="link-text">Source Code</span>
                    </a>
                    <a style={{display: projectLinkDisplay}} target={linkTargets.projectLink} rel="noreferrer" className="project-link" href={projectLink} aria-label={`${props.projectTitle} live version link`}>
                        <DesktopWindowsIcon id="desktop-icon" fontSize="large" style={{ color: '#33aab9' }}/>
                        <span className="link-text">Live Version</span>
                    </a>
                </div>
                <h2 className="project-details-header">About {props.projectTitle}</h2>
                <p className="project-description">{props.projectDescription}</p>
                <h2 className="project-details-header">Project Objectives</h2>
                <p className="project-objectives">{props.projectObjectives}</p>
                <ul className="project-technologies">
                    { 
                        props.techs.map(technology => <TechCard key={`${props.projectTitle} ${technology}`} technology={technology}/>)
                    }
                </ul>
            </div>
        </article>
    )
}

export default Project;