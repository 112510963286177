import './Contact.css'
import EmailIcon from '@material-ui/icons/Email';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

function Contact() {
    return (
        <div id="contact">
            <span className="section-header">Contact Me</span>
            <div className="contact-icons-container">
                <a aria-label="Send me an email" href="mailto:andrewpbumpus@gmail.com"><EmailIcon className="contact-icon" /></a>
                <a aria-label="Visit my LinkedIn profile" href="https://www.linkedin.com/in/apbumpus/"><LinkedInIcon className="contact-icon" /></a>
            </div>
        </div>
    )
}

export default Contact;