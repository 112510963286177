import papertrailMobileDashboard from './papertrail/mobile_dashboard.png'
import memupMobileDash from './memup/mobile_dashboard.png'

const papertrailImages = {
    mobileDash: papertrailMobileDashboard,
}

const memupImages = {
    mobileDash: memupMobileDash,
}

export {
    papertrailImages,
}


export {
    memupImages,
}