import './TechCard.css';

function TechCard(props) {
  const technology = props.technology;

  return (
    <li className="tech-list-element">
        { technology }
    </li>
  );
}

export default TechCard;
