import "./About.css";
import TechCard from "./TechCard/TechCard.jsx";

function About() {
  const technologies = [
    "HTML/CSS/JS",
    "Angular",
    "React",
    "C#",
    "Python",
    ".NET Core",
    "Firebase",
    "Git",
  ];

  return (
    <section className="about-me">
      <div className="about-info">
        <div className="about-header">
          <h1 className="about-title">Hi, I'm Andrew.</h1>
          <h2 className="about-subtitle">
            I'm a full stack web developer based in Michigan, USA. I'm currently
            looking to use my skills in a full time development role.
          </h2>
        </div>
      </div>
        <div className="about-techs">
          <h1 className="techs-title">
            I have experience using these technologies.
          </h1>
          <ul className="techs-list">
            {technologies.map((technology) => <TechCard key={`About ${technology}`} technology={technology} />)}
          </ul>
        </div>
    </section>
  );
}

export default About;
